import React, { useEffect } from "react";
import logo from '../images/logo.svg';
import { ForgotPasswordPopUp } from '../components/popups/forgotpass';
import { SignInPopUp } from '../components/popups/signin';
import { SignUpPopUp } from '../components/popups/signup';
import '../styles/style.css';
import RightTopCorner from '../components/buttonsOrAccount';
import arrowRight from '../images/arrow-right.svg'
import DeleteProfilePopUp from '../components/popups/deleteAccounPopUp'
import {PaymentWindow} from '../components/popups/subscriptionPage'

function Header({ MoveToBoards, MoveToMain, MoodBoardName }) {
    return (
        <>
            <header className="manage">
                <div id="logo" onClick={MoveToMain ? MoveToMain : null}>
                    <img src={logo} />
                </div>
                <a id="my_boards" onClick={MoveToBoards ? MoveToBoards : null}>
                    My boards
                </a>
                {MoodBoardName ? 
                    <>
                    <div className="arrow-box">
                        <img src={arrowRight}/>
                    </div>
                    <div id="Untitled_board">{(!MoodBoardName ? 'Untitled board' : MoodBoardName)}</div>
                    </>
                 : null}
                <RightTopCorner/>
            </header>
            <SignInPopUp />
            <SignUpPopUp />
            <ForgotPasswordPopUp />
            <DeleteProfilePopUp />
            {/* <PaymentWindow/> */}
        </>
    )
}

export {Header}