import axios from 'axios'
import inMemoryID from '../services/id'
import inMemoryEmail from '../services/mail'
import JWTAccess from '../services/memory'

export async function makeRefresh(user) {

    if (user) {
        await axios.post(`https://securetoken.googleapis.com/v1/token?key=AIzaSyDcdmBXlMqNndavdRxKeCwMFOM1i2QQJ7Q`, {
            grant_type: 'refresh_token',
            refresh_token: user.refreshToken
        }).then((res) => {
            const newIdToken = res.data.id_token;
            JWTAccess.setToken(newIdToken, res.data.expires_in * 1000);
            inMemoryID.setID(res.data.user_id);
            inMemoryEmail.setEmail(user.email)
        }).catch((err) => {
            throw err
        })
    }
};
