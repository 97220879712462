import DB from '../db'
import APIclient from './client'
import inMemoryID from '../services/id'
import { backEndMoodBoards } from '../constants/API'

// client for operating with moodboards
const ResourceClient = new APIclient(backEndMoodBoards)

export async function getAllUsersBoards() {
    try {
        await ResourceClient.get("/all-boards").then((res) => {
            DB.moodBoards.clear()
            res.data.forEach(board => {
                DB.moodBoards.add({
                    name: board.name,
                    photos: board.photos,
                    mbid: board._id,
                    created_by: board.created_by,
                    recentSearches: board.recentSearches,
                    users_with_access: board.users_with_access
                })
            })
            return res
        })
    } catch (err) {
        throw err
    }
}

export async function deleteAllUsersBoards() {
    try {
        await ResourceClient.delete("/delete-all-boards")
        .then(() => {
            DB.moodBoards.clear()
        })
    } catch (err) {
        throw err
    }
}

export async function getMoodBoard(moodBoardId) {
    try {
        const moodBoard = await ResourceClient.get(`/board/${moodBoardId}`)
        
        return moodBoard
    } catch (err) {
        throw err
    }
}

export async function saveMBinDB(newMoodBoard, MoodBoardName, RecentSearchPhrases) {
    const response = await ResourceClient.post('/new-board', {
        name: MoodBoardName,
        photos: newMoodBoard,
        createdBy: inMemoryID.getID(),
        recentSearches: RecentSearchPhrases
    })
    return response
}

export async function deleteMBfromDB(id) {
    const response = await ResourceClient.delete(`/delete-board/${id}`)
    return response
}

export async function editMBinDB(newMoodBoard, MoodBoardName, id, RecentSearchPhrases, createdBy) {
    const response = await ResourceClient.put(`/edit-board/${id}`, {
        name: MoodBoardName,
        photos: newMoodBoard,
        recentSearches: RecentSearchPhrases,
        createdBy: createdBy
    })
    return response
}

export async function createNewComment(comment, imageId, x, y, boardId) {
    const response = await ResourceClient.patch(`/new-comment/${boardId}/${imageId}/${x}/${y}`, {
        message: comment
    });

    let Moodboard = await DB.moodBoards.get({ mbid: boardId })

    if (!Moodboard) {
        return response
    }

    if (Moodboard.created_by === inMemoryID.getID()) {

        const editCommentedImageInMB = (board) => {
    
            const newArray = [];
    
            board.forEach((image) => {
                if (image.id === response.data.id) {
                    newArray.push(response.data)
                } else {
                    newArray.push(image)
                }
            })
    
            return newArray;
        }
    
        const updatedPhotosArray = editCommentedImageInMB(Moodboard.photos);
    
        Moodboard.photos = updatedPhotosArray;
        
        DB.moodBoards.update(Moodboard.id, { photos: updatedPhotosArray });
    
        return response
    } else {
        return response
    }

}

export async function manageAccessToNewUser(users_email, mbid, can_comment, can_edit) {
    const response = await ResourceClient.post('/grant-comment-access', {
        users_email: users_email,
        mbid: mbid,
        can_comment: can_comment, 
        can_edit: can_edit
    });

    return response;
}

export async function deleteComment(mbid, commentID, commentPositionID, imageID) {
    const response = await ResourceClient.delete(`/delete-comment/${mbid}/${imageID}/${commentPositionID}/${commentID}`);

    let Moodboard = await DB.moodBoards.get({ mbid: mbid });

    if (!Moodboard) {
        return response;
    }

    if (Moodboard.created_by === inMemoryID.getID()) {

        const editCommentedImageInMB = (board) => {
    
            const newArray = [];
    
            board.forEach((image) => {
                if (image.id === response.data.id) {
                    newArray.push(response.data)
                } else {
                    newArray.push(image)
                }
            })
    
            return newArray;
        }
    
        const updatedPhotosArray = editCommentedImageInMB(Moodboard.photos);
    
        Moodboard.photos = updatedPhotosArray;
        
        DB.moodBoards.update(Moodboard.id, { photos: updatedPhotosArray });
    
        return response;
    } else {
        return response;
    }

}