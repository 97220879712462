import { SearchFilter } from "../components/filter";
import { useRef, useEffect, useState } from 'react';
import { signUp_popup_open, search_keyword } from '../scripts/script';
import { filters_open } from '../components/filter'
import { useParams, useSearchParams } from 'react-router-dom';
import filters from '../images/sliders.svg'
import searchIcon from '../images/search.svg'
import callToImageServices from "../requests/imagesAPI";
import '../styles/style.css'
import { LoadingScreen } from "../components/loader";
import { useNavigate } from 'react-router-dom';
import DB from "../db";
import { ResultsAndMB } from "../components/resultsAndMB";
import { saveMoodBoardInDB } from '../scripts/saveMB';
import inMemoryID from '../services/id'
import { SharePopUp } from '../components/popups/share'
import { deleteMBfromDB } from "../requests/boardsAPI";
import ClearMoodBoardPopUp from '../components/popups/clearMBpopUp'
import ImageSizeLimitReached from '../components/popups/tooBigFilePopUp'
import SearchTextBar from '../components/textBar'
import { ErrorAlert } from "../components/errors";
import AutoSaveComponent from '../components/autoSave'
import BoardsLimitReached from '../components/boardsLimitReached'
import { boardsLimitReachedPopUpOpen } from "../scripts/script";
import MoodBoardFunctionsPanel from '../components/functionalPanel'
import {Header} from '../components/header'


function ReturnFind() {

  // moodboard ID
  let { moodBoard } = useParams();

  const [MoodBoard, setMoodBoard] = useState({ name: '' });

  const createdID = useRef();

  // getting moodBoard id if it exists in path
  useEffect(() => {
    if (moodBoard) {
      createdID.current = moodBoard;
      setMBtoState(moodBoard)
    }
  }, [moodBoard])

  const setMBtoState = async (id) => {
    try {
      let MB;
      if (id != undefined) {
        MB = await DB.moodBoards.get({mbid: id})
      }
      if (MB){
        setMoodBoard(MB)
      }
    } catch (err) {

    }
  }

  useEffect(() => {
    if (MoodBoard.RecentSearches) {
      setSearchPhrases(MoodBoard.RecentSearches)
    }
  }, [MoodBoard])

  const isBoardSaved = useRef(false);

  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();

  // function for clearing all photos from moodboard. Its useRef, that im passing to the resultAndMB component, and then giving this useRef clear function
  const clearMBstate = useRef()

  // cleaning all selected images from the state
  async function clearMBfunction() {
    clearMBstate.current()
    if (createdID.current && !moodBoard) {
      await deleteMBfromDB(createdID.current).then((res) => {
        createdID.current = undefined
      })
    }
  }

  const SaveMoodBoardFunction = async () => {
    return await saveMoodBoardInDB(Grid, MoodBoard.name, createdID.current, searchPhrases, MoodBoard ? MoodBoard.users_with_access : [], MoodBoard.created_by)
  }

  // redirect to main 
  const MoveToMain = async () => {
    await redirectFunction("/");
  }

  const redirectFunction = async (path) => {
    if (inMemoryID.getID() && !isBoardSaved.current) {
      isBoardSaved.current = true;
      SaveMoodBoardFunction().then(() => {
        navigate(path)
      }).catch((err) => {
        validateError(err)
      })
    } else if (!isBoardSaved.current && !inMemoryID.getID()) {
      signUp_popup_open()
    } else {
      navigate("/boards/")
    }
  }

  // redirect to all boards list
  const MoveToBoards = async () => {
    await redirectFunction("/boards/");
  }

  const [sharePopUp, setSharePopUp] = useState();

  function createSharePopUp() {
    if (moodBoard || createdID.current) {
      setSharePopUp(<SharePopUp boardId={createdID} uniqueId={1} />);
    }
  }

  // creating URL of moodboard and creating share pop-up it if ID exists
  useEffect(() => {
    createSharePopUp()
  }, [])

  // Shows a pop-up if users have exceeded the limits for creating boards
  const validateError = (error) => {
    if (error.response) {
      console.log(error)
      if (error.response.data.error.includes('You have reached the maximum number') && error.response.data.status === 403) {
        boardsLimitReachedPopUpOpen()
      } else {
        setErrorMessage(error.response.data)
      }
    }
  }

  // images from API request
  const [resultImages, setResultImages] = useState([]);

  // getting query from the URL string
  const [searchQuery, setSearchQuery] = useSearchParams();

  let searchTerm = searchQuery.get('query') || null
  let searchphotoOrientation = searchQuery.get('photoOrientation') || null
  let searchphotoColorTheme = searchQuery.get('photoColor') || null
  let searchFlickr = searchQuery.get('flickrSearch') || null
  let searchUnsplash = searchQuery.get('unsplashSearch') || null
  // let searchadobe = searchQuery.get('adobeSearch') || null;

  // getting is user changing moodBoard name at the current moment to show or hide tooltip
  const [inputState, setInputState] = useState({ isHovered: false, isFocused: false });

  // creating useRefs of elements, that will be added as props to components with result photos and grid-MoodBoard section
  const Grid = useRef(null);

  // state with user's search terms
  const [searchPhrases, setSearchPhrases] = useState([]);
  
  // query on the Find page
  const searchInput = useRef(null);

  const isFirstSearchWasMade = useRef(false);

  // getting images from image stocks API with users query
  const handleSearch = async (e) => {

    if (e) {
      e.preventDefault()
    }

    let search = searchInput.current.value

    if (inMemoryID.getID() || !isFirstSearchWasMade.current) {
      if (search != '' && search) {
        if (resultGrid.current) {
          resultGrid.current.scrollTop = 0
        }
  
        isFirstSearchWasMade.current = true;
        
        setPage(1)
    
        const params = {}
    
        if (search) { params.query = search; }
        if (searchphotoOrientation) { params.photoOrientation = searchphotoOrientation; }
        if (searchphotoColorTheme) { params.photoColor = searchphotoColorTheme; }
        if (searchUnsplash) { params.unsplashSearch = true }
        if (searchFlickr) { params.flickrSearch = true }
        // if (searchadobe) { params.adobeSearch = true }
    
        setSearchQuery(params)
    
        await fetchData.current(1)
  
        setSearchPhrases(previousSearches => {
          if (previousSearches.length < 5 && search != '') {
            return [...previousSearches, search]
          } else if (previousSearches.length >= 5 && search != '') {
            return [...previousSearches.splice(0, 1), search]
          } else {
            return []
          }
        })
      } else {
        setResultImages([])
      }
    }
  }

  const resultGrid = useRef(null);

  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchData.current = async (newPage) => {
      const newImages = await callToImageServices(searchInput.current.value, newPage, searchphotoOrientation, searchphotoColorTheme, searchUnsplash, searchFlickr);
      if (newImages) {
        setResultImages(newImages);
      }
    };
  }, [searchQuery])

  const fetchData = useRef();

  // ref to prevent multiple calls to images backend API
  const hasRequested = useRef(false);

  function callToImageServiceshFunc() {
    setPage((count) => {
      if (count >= 5) {
        hasRequested.current = false;
        return count;
      } else {
        const newPage = count + 1;

        fetchData.current(newPage);

        setTimeout(() => {
          hasRequested.current = false;
        }, 2000)
        
        return newPage;
      };
    });
  };

  // event listener for user scrolling
  useEffect(() => {
    const handleScroll = async () => {
      if (resultGrid.current.scrollTop + window.innerHeight * 2 >= resultGrid.current.scrollHeight && searchInput.current.value != '' && resultImages && !hasRequested.current) {
        hasRequested.current = true
        callToImageServiceshFunc()
      };
    };

    setTimeout(() => {
      if (resultGrid.current && inMemoryID.getID()) {
        resultGrid.current.addEventListener('scroll', handleScroll);
      };

      return () => {
        if (resultGrid.current && inMemoryID.getID()) {
          resultGrid.current.removeEventListener('scroll', handleScroll);
        };
      };
    }, 1000)
  }, [resultImages]);
  
  useEffect(() => {
    if (searchInput.current.value.trim() != '') {
      handleSearch()
    } else if (searchInput.current.value.trim() == '') {
      setResultImages(
        <div className="select-block-empty" >
          <p style={{width:"230px", textAlign:"center"}}>Enter your first search request to start creating a mood board</p>
        </div>
      )
    }
  }, [searchInput.current]);
    
  return (
    <>
      <SearchFilter searchFunc={handleSearch} />
      <Header  MoveToBoards={MoveToBoards} MoveToMain={MoveToMain} MoodBoardName={MoodBoard.name} />
      {sharePopUp}
      <ErrorAlert message={errorMessage} />
      <BoardsLimitReached />
      <ImageSizeLimitReached/>
      <ClearMoodBoardPopUp clearMB={clearMBfunction} />
      <div className="search-box-container">
        <div className="block-2">
          <div style={{display: 'flex', width: '100%'}}>
            <div className="search-box-2">
              <a className="sliders" onClick={filters_open}>
                <img src={filters} className="img-sliders"/>
              </a>
              <form onSubmit={handleSearch} style={{width: "90%"}}>
                  <input className="input-1" id="input-2" type="text" placeholder="Search (enter keywords)" autoComplete="on" defaultValue={searchTerm} ref={searchInput}/>
              </form>
              <a id="search-button-2" onClick={handleSearch}>
                <img src={searchIcon} className="img-search" style={{height: "24px", margin: "13px"}}/>
              </a>
            </div>
          </div>
          <MoodBoardFunctionsPanel validateError={validateError} navigate={navigate} createSharePopUp={createSharePopUp} moodBoard={moodBoard} setInputState={setInputState} inputState={inputState} MoodBoard={MoodBoard} setMoodBoard={setMoodBoard} Grid={Grid} createdID={createdID} SaveMoodBoardFunction={SaveMoodBoardFunction} isBoardSaved={isBoardSaved} />
          </div>
        <div className="under-block-2">
          <div className="text-box-2">
            <SearchTextBar searchKeyWords={searchPhrases} searchFunc={handleSearch} />
          </div>
        </div>
      </div>
      <AutoSaveComponent SaveMoodBoardFunction={SaveMoodBoardFunction} createSharePopUp={createSharePopUp} validateError={validateError} createdID={createdID} Grid={Grid} />
      <ResultsAndMB result={resultImages} page={page} search={searchTerm} gridRef={Grid} resultRef={resultGrid} editImages={MoodBoard.photos} isCalledUnsplashFunc={callToImageServiceshFunc} clearMoodBoardFunc={clearMBstate} />
    </>)
}

function Find() {
  // loader function
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
      <>
      {loading ? ( <div className='loader'><LoadingScreen loading={loading}/></div> ) : null}
      <ReturnFind/>
      </>
  )
}

export default Find