import React, { useState, useRef, useEffect } from "react"
import { signIn_popup_open, forgotPass_popup_open, signUp_popup_open } from "../../scripts/script"
import '../../styles/style.css'
import validationSchema from '../../validators/auth'
import { useNavigate } from "react-router-dom"
import { ErrorPopUp } from '../errors'
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { handleSignIn, handleSignInWithPassAndEmail } from "../../requests/authAPI"
import { auth, provider } from '../../firebaseConnect';
import { signInWithPopup } from 'firebase/auth'
import JWTAccess from '../../services/memory'
import inMemoryID from '../../services/id'
import inMemoryEmail from '../../services/mail'
import { LoadingScreen } from '../loader'


function SignInPopUp() {

    const [signInPass, setSignInPass] = useState();

    const [signInEmail, setSignInEmail] = useState();

    async function signIn() {
        if (signInEmail && signInPass && !inMemoryID.getID()) {
            if (signInEmail.trim() != '' && signInPass.trim() != '') {
                await validationSchema.validate({ email: signInEmail, password: signInPass })
                    .then(async () => {
                    activateLoader()
                    await handleSignInWithPassAndEmail(signInEmail, signInPass).catch((err) => {
                        if (err.response) {
                            if (err.response.status === 401) {
                                verifyEmailButton.current = <button onClick={verifyEmail} className="btn-confirm">Verify email</button>
                            }
                            setErrorPopUp(new Error(err.response.data.error))
                        } else {
                            setErrorPopUp(err)
                        }
                        auth.signOut();
                        setLoader(null)
                        inMemoryID.deleteID();
                        inMemoryEmail.deleteEmail();
                        JWTAccess.deleteToken();
                    })
                }).catch((err) => {
                    setErrorPopUp(err)
                })
            }
        }
    }

    const activateLoader = () => {
        setLoader(
            <div style={{height: '100%', width: '100%', background: '#FFF', position: 'absolute', top: '0', borderRadius: '5px'}}>
                <LoadingScreen loading={true} />
            </div>
        )
    }

    const [loader, setLoader] = useState()

    const verifyEmailButton = useRef();

    async function verifyEmail() {
        await signInWithEmailAndPassword(auth, signInEmail, signInPass).then(async (credentials) => {
            await sendEmailVerification(credentials.user)
            navigate("/verify-email")
        }).catch(err => {
            throw err
        })
    }

    const navigate = useNavigate();

    const [errorPopUp, setErrorPopUp] = useState()

    async function signInWithGoogle() {
        if (!inMemoryID.getID()) {
            activateLoader()
            signInWithPopup(auth, provider).then(async (result) => {
    
                await handleSignIn(result).catch((err) => {
                    auth.signOut();
                    setLoader(null)
                    setErrorPopUp(Error(err.response.data.error));
                    inMemoryID.deleteID();
                    inMemoryEmail.deleteEmail();
                    JWTAccess.deleteToken();
                })
            }).catch((err) => {
                setErrorPopUp(Error(err.code));
                setLoader(null)
            })
        }
    }

    return (
        <>
            <div className="all" id="signIn">
                <a onClick={signIn_popup_open} className="popup-close"></a>
                <div className="sign-in-popup">
                    <div className="input-box">
                        <h2>Welcome back</h2>
                        <p style={{color:"grey", margin: "59px 0 0 0"}}>Email</p>
                        <div className="search-box-3">
                            <input className="input-2" onChange={(e) => {setSignInEmail(e.target.value)}} type="email" autoComplete="on"/>
                        </div>
                        <p style={{color:"grey", margin: "10px 0 0 0"}}>Password</p>
                        <div className="search-box-3">
                            <input className="input-2" onChange={(e) => {setSignInPass(e.target.value)}} type="password"/>
                        </div>
                        <a onClick={forgotPass_popup_open} style={{color: "#2D9CDB",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal"}}
                        >Forgot password?</a>
                        <div className="buttons-vertical-container">
                            <button onClick={signIn} className="btn-confirm">Sign in</button>
                            <button className="btn-confirm" onClick={signInWithGoogle} >Continue with Google</button>
                            {verifyEmailButton.current}
                        </div>
                        <p style={{ color: "grey", textAlign: 'center', margin: '10px 0 0 0', fontSize: '13px' }}>No account?
                            <a style={{ color: 'rgb(45, 156, 219)' }} onClick={signUp_popup_open}> Sign Up</a>
                        </p>
                    </div>
                    <ErrorPopUp message={errorPopUp} />
                    {loader}
                </div>
            </div>  
        </>
    )
}
export {SignInPopUp}