import React, { useEffect, useState, useRef } from 'react';
import close from '../images/close-icon.svg';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';
import ColorSelectors from '../components/colorSelectors'
import '../styles/style.css';

export function filters_open() {
    let filters = document.querySelector('.side-window');
    let all = document.querySelector('#filters');
    filters.classList.toggle('active');
    all.classList.toggle('active');
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = body.style.overflow === 'hidden' ? 'auto' : 'hidden';
}

function SearchFilter(props) {
    const currentPath = useLocation().pathname;

    const colors = [
        { color: null, background: null },
        { color: 'black_and_white', background: null },
        { color: 'black', background: '#000000' },
        { color: 'white', background: '#FFFFFF' },
        { color: 'yellow', background: 'rgba(242, 201, 76, 1)' },
        { color: 'orange', background: 'rgba(242, 153, 74, 1)' },
        { color: 'red', background: 'rgba(235, 87, 87, 1)' },
        { color: 'purple', background: 'rgba(155, 81, 224, 1)' },
        { color: 'magenta', background: 'rgba(222, 107, 255, 1)' },
        { color: 'green', background: 'rgba(39, 174, 96, 1)' },
        { color: 'teal', background: 'teal' },
        { color: 'blue', background: 'rgba(40, 134, 242, 1)' }
    ]

    let { moodBoard } = useParams();

    const [PhotoPos, setOrientation] = useState();

    const [Colour, setColour] = useState();

    const Sources = useRef({
        flickr: false,
        unsplash: true,
        // adobe: false
    })

    let [searchParams, setSearchParams] = useSearchParams();

    const filterStorage = window.localStorage;

    // radio buttons for photo orientation select
    const handleDeleteOrientation = () => {
        setOrientation(null);
        rememberSelect(null, Colour)
        filterStorage.removeItem('orient');
    };

    const handleChangeOrientation = (e) => {
        setOrientation(e.target.value);
        rememberSelect(e.target.value, Colour)
    };

    // functions to get color
    const handleChangeColour = (e) => {
        setColour(e.target.value);
        rememberSelect(PhotoPos, e.target.value)
    };

    const handleDeleteColour = (e) => {
        setColour(null);
        rememberSelect(PhotoPos, null)
        filterStorage.removeItem('photoColour');
    };

    const handleSource = (e) => {

        if (!Sources.current[e.target.value]) {
            Sources.current[e.target.value] = true
        } else if (Sources.current[e.target.value]) {
            Sources.current[e.target.value] = false
        }
        rememberSelect(PhotoPos, Colour);
    }

    useEffect(() => {

        const currentOrientation = searchParams.get('photoOrientation') || null;
        const currentColor = searchParams.get('photoColor') || null;
        const searchFlickr = searchParams.get('flickrSearch') || false;
        const searchUnsplash = searchParams.get('unsplashSearch') || false;
        // const searchAdobe = searchParams.get('adobeSearch') || false;

        if (currentPath == '/find/' || currentPath == `/find/${moodBoard}/`) {
    
            setColour(currentColor);
            setOrientation(currentOrientation);
            Sources.current = {
                unsplash: searchUnsplash == 'true' ? true : false,
                flickr: searchFlickr == 'true' ? true : false,
                // adobe: searchAdobe == 'true' ? true : false,
            }

        }

        const orientInput = document.querySelector(
            `input[value="${currentOrientation}"]`
        );

        if (orientInput) {
            orientInput.setAttribute('checked', true);
        } else {
            document.querySelector('#radio-0').setAttribute('checked', true);
        }

        const colorInput = document.querySelector(
            `input[value="${currentColor}"]`
        );

        if (colorInput) {
            colorInput.setAttribute('checked', true);
        } else {
            document.querySelector('#color-1').setAttribute('checked', true);
        }

        if (Sources.current.flickr) {
            document.querySelector(`input[value="flickr"]`).checked = true
        }
        if (Sources.current.unsplash) {
            document.querySelector(`input[value="unsplash"]`).checked = true
        }
        // if (Sources.current.adobe) {
        //     document.querySelector(`input[value="adobe"]`).checked = true
        // }

        filterStorage.removeItem('orient');
        filterStorage.removeItem('photoColour');
        // filterStorage.removeItem('searchSources');
    }, []);

    const rememberSelect = (position, color) => {

        if (currentPath == '/find/' || currentPath == `/find/${moodBoard}/`) {

            const params = {}

            const search = searchParams.get('query') || '';

            if (search) {params.query = search }
            if (position) {params.photoOrientation = position }
            if (color) { params.photoColor = color }
            if (Sources.current.unsplash) { params.unsplashSearch = true }
            if (Sources.current.flickr) { params.flickrSearch = true }
            // if (Sources.current.adobe) { params.adobeSearch = true }

            setSearchParams(params)
        }

        filterStorage.setItem('orient', position);
        filterStorage.setItem('photoColour', color);
        filterStorage.setItem('searchSources', JSON.stringify(Sources.current))
    };

    const closeFilters = () => {
        rememberSelect(PhotoPos, Colour);
        filters_open();
    }

    return (
        <div className="all" id="filters">
            <a onClick={closeFilters} className="popup-close"></a>
            <div className="side-window">
                <div className="filters-manage">
                    <h2 style={{ marginTop: 'auto', padding: '15px 0' }}>
                        FILTERS
                    </h2>
                    <a onClick={filters_open} className="side-window-close">
                        <img src={close} />
                    </a>
                </div>
                {/* <h2 className="search-categories">Grid size</h2>
            <div className="min-block">
              <fieldset>
                <label className="container">
                  <input type="radio" className="check-highload" name="grid-size" checked/>
                  <span className="highload2">6</span>
                </label>
                <label className="container">
                  <input type="radio" className="check-highload" name="grid-size"/>
                  <span className="highload2">9</span>
                </label>
                <label className="container">
                  <input type="radio" className="check-highload" name="grid-size"/>
                  <span className="highload2">12</span>
                </label>
                <label className="container">
                  <input type="radio" className="check-highload" name="grid-size"/>
                  <span className="highload2">15</span>
                </label>
                <label className="container">
                  <input type="radio" className="check-highload" name="grid-size"/>
                  <span className="highload2">20</span>
                </label>
              </fieldset>
            </div> */}
                <h2 className="search-categories">Image orientation</h2>
                <fieldset>
                    <div className="min-block-2">
                        <label className="page-pos">
                            <input
                                type="radio"
                                className="rect-highload"
                                name="orientation"
                                onChange={handleDeleteOrientation}
                                id="radio-0"
                            />
                            <div className="rectangle-cont">
                                <div className="rectangle-cont0"></div>
                                <div className="rectangle-cont1">
                                    <div className="rectangle-cont1-1"></div>
                                    <div className="rectangle-cont1-1"></div>
                                </div>
                            </div>
                        </label>
                        <label className="page-pos">
                            <input
                                type="radio"
                                className="rect-highload"
                                name="orientation"
                                onChange={handleChangeOrientation}
                                id="radio-1"
                                value="landscape"
                            />
                            <div className="rectangle0"></div>
                        </label>
                        <label className="page-pos">
                            <input
                                type="radio"
                                className="rect-highload"
                                name="orientation"
                                onChange={handleChangeOrientation}
                                id="radio-2"
                                value="portrait"
                            />
                            <div className="rectangle1"></div>
                        </label>
                        <label className="page-pos">
                            <input
                                type="radio"
                                className="rect-highload"
                                name="orientation"
                                onChange={handleChangeOrientation}
                                id="radio-3"
                                value="squarish"
                            />
                            <div className="rectangle2"></div>
                        </label>
                    </div>
                </fieldset>
                <h2 className="search-categories">Colors</h2>
                <fieldset className="colors">
                    <ColorSelectors colors={colors} handleChangeColour={handleChangeColour} handleDeleteColour={handleDeleteColour} />
                </fieldset>
                {/* <h2 className="search-categories">Image stock services</h2>
                <fieldset className='source'>
                    <label>
                        <input
                            type="checkbox"
                            className='side-window-checkbox-source'
                            value='unsplash'
                            id='source-1'
                            name="source"
                            onChange={handleSource}
                        />
                        <div className='source-selector'>
                            <img src={unsplashLogo} alt="unsplash icon" id="unsplash-icon" />
                        </div>
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            className='side-window-checkbox-source'
                            value='flickr'
                            id='source-2'
                            name="source"
                            onChange={handleSource}
                        />
                        <div className='source-selector'>
                            <img src={flickrLogo} alt="flickr logo" id="flickr-icon" />
                        </div>
                    </label>
                </fieldset> */}
                <h2 className="search-checkbox-categories" style={{marginTop: '30px'}}>Image stocks</h2>
                <fieldset>
                    <div className="other-selectors">
                        <div className='checkbox-row'>
                            <label className="container">
                                <input
                                    type="checkbox"
                                    className='side-window-checkbox-source'
                                    value='unsplash'
                                    id='source-1'
                                    name="source"
                                    onChange={handleSource}
                                />
                                <span className="highload2"></span>
                            </label>
                            <p>Unsplash</p>
                        </div>
                        <div className='checkbox-row'>
                            <label className="container">
                                <input
                                    type="checkbox"
                                    className='side-window-checkbox-source'
                                    value='flickr'
                                    id='source-2'
                                    name="source"
                                    onChange={handleSource}
                                />
                                <span className="highload2"></span>
                            </label>
                            <p>Flickr</p>
                        </div>
                        {/* <div className='checkbox-row'>
                            <label className="container">
                                <input
                                    type="checkbox"
                                    className='side-window-checkbox-source'
                                    value='adobe'
                                    id='source-3'
                                    name="source"
                                    onChange={handleSource}
                                />
                                <span className="highload2"></span>
                            </label>
                            <p>Adobe</p>
                        </div> */}
                    </div>
                </fieldset>
                {/* <h2 className="search-checkbox-categories">Image categories</h2>
                <fieldset>
                    <div className="other-selectors">
                        <div className='checkbox-row'>
                            <label className="container">
                                <input type="checkbox" className="check-highload" name="image"/>
                                <span className="highload2"></span>
                            </label>
                            <p>Abstract</p>
                        </div>
                        <div className='checkbox-row'>
                            <label className="container">
                                <input type="checkbox" className="check-highload" name="image"/>
                                <span className="highload2"></span>
                            </label>
                            <p>Concept</p>
                        </div>
                        <div className='checkbox-row'>
                            <label className="container">
                                <input type="checkbox" className="check-highload" name="image"/>
                                <span className="highload2"></span>
                            </label>
                            <p>Graphic</p>
                        </div>
                        <div className='checkbox-row'>
                            <label className="container">
                                <input type="checkbox" className="check-highload" name="image"/>
                                <span className="highload2"></span>
                            </label>
                            <p>Texture</p>
                        </div>
                            <div className='checkbox-row'>
                                <label className="container">
                                <input type="checkbox" className="check-highload" name="image"/>
                                <span className="highload2"></span>
                            </label>
                            <p>Background</p>
                        </div>
                    </div>
                </fieldset> */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '40px 0 30px 0' }}>
                    <button className='search-button' onClick={() => {
                        props.searchFunc()
                        filters_open()
                    }}>Search</button>
                </div>
            </div>
        </div>
    )
}

export { SearchFilter }