import React, { useEffect, useState } from 'react';
import { sharePopUp_open } from '../../scripts/script';
import close from '../../images/close-icon.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { frontEndDomain } from '../../constants/API';
import '../../styles/style.css';

export function SharePopUp(props) {

    const [saveMessage, setSaveMessage] = useState()

    const [moodBoardID, setMoodBoardID] = useState()

    function handleCopy() {
        setSaveMessage("Copied to clipboard!")
        setTimeout(() => {
            setSaveMessage(null)
        }, 3000)
    }

    function closePopUp() {
        sharePopUp_open(props.uniqueId)
    }

    useEffect(() => {
        if (props.boardId.current) {
            setMoodBoardID(props.boardId.current)
        }
    }, [props.boardId.current])

    return (
        <>
            <div className="all" id={"share-" + props.uniqueId}>
            <a onClick={closePopUp} className="popup-close"></a>
                <div className="share-popup">
                    <div style={{position: 'absolute', top: '0', right: '0', cursor: 'pointer'}} onClick={closePopUp} >
                        <img src={close} alt="close icon"/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'start'}}>
                        <h2>Share this board  with the team and customers</h2>
                    </div>
                    <p style={{ margin: '12px 0'}}>Allow people to view a read-only a board by sending this link:</p>
                    <section>
                        <div className='share-popup-text-cont'>
                            <p>{`${frontEndDomain}/boards/${moodBoardID}/`}</p>
                        </div>
                        <CopyToClipboard text={`${frontEndDomain}/boards/${moodBoardID}/`} onCopy={handleCopy}>
                            <button>Copy</button>
                        </CopyToClipboard>
                    </section>
                    <section>
                        <p style={{height: '14px'}}>{saveMessage}</p>
                    </section>
                    {props.children ? props.children : null}
                </div>
            </div>
        </>
    )
}