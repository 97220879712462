import React, { useEffect, useState, useRef, useMemo } from 'react';
import CommentIcon from '../images/comment.svg';
import { createNewComment, deleteComment } from '../requests/boardsAPI';
import { useParams } from 'react-router-dom';
import inMemoryID from '../services/id';
import GetImageAspectRatio from '../scripts/getImageAspectRatio'

function CommentsGrid({ setPreviewImageData, image, isUserHaveAccess }) {

    const [commentsArray, setCommentsArray] = useState([]);

    const [commentsComponents, setCommentsComponents] = useState([]);

    const [imageID, setImageID] = useState('');

    let { moodBoard } = useParams();

    const setCommentsToTheState = (dimensions) => {
        if (imageID !== image.id) {
            setCommentsComponents(image.comments.map((item, i) => {
                return <Comment key={'comment-'+i} imageGridSize={dimensions} data={item} imageID={image.id} />
            }));
            setCommentsArray(image.comments);
        } else if (imageID === image.id) {
            setCommentsComponents(prevComments => {
                const newComponents = [];

                if (image.comments.length >= prevComments.length) {
                    image.comments.forEach((item, i) => {
                        if (commentsArray[i]) {
                            if (JSON.stringify(item) !== JSON.stringify(commentsArray[i])) {
                                if (item.xPos === commentsArray[i].xPos && item.yPos === commentsArray[i].yPos) {
                                } else {
                                    newComponents.push(<Comment key={'comment-'+(item.xPos)+'-'+(item.yPos)} imageGridSize={dimensions} data={item} imageID={image.id} />)
                                }
                            }
                        } else {
                            newComponents.push(<Comment key={'comment-'+(item.xPos)+'-'+(item.yPos)} imageGridSize={dimensions} data={item} imageID={image.id} />)
                        }
                    })
    
                    return [...prevComments, ...newComponents]
                } else {
                    return [...prevComments]
                }

            });
            setCommentsArray(image.comments);
        }
    }

    function Comment({ data, imageGridSize, imageID }) {
        
        const [position, setPosition] = useState({ x: null, y: null });

        const [comments, setComments] = useState([]);

        const input = useRef();

        function setInputEmpty() {
            input.current.value = ('');
        }

        const [commentTextLength, setCommentTextLength] = useState(0);

        const commentsList = useRef();

        const commentInput = useRef();

        const container = useRef();

        function updateStateValue(e) {
            if (e.target.value.length < 201) {
                setCommentTextLength(e.target.value.length)
                input.current.value = e.target.value
            }
        }

        const isAddCommentBtnClicked = useRef(false);

        function sendNewCommentRequest(e) {
            e.preventDefault();
            if (input.current.value != '' && input.current.value && isAddCommentBtnClicked.current === false) {
                isAddCommentBtnClicked.current = true
                createNewComment(input.current.value, image.id, data.xPos, data.yPos, moodBoard).then((res) => {
    
                    setPreviewImageData(res.data);

                    setInputEmpty();

                    setCommentTextLength(0);

                    res.data.comments.forEach((item) => {
                        if (item.commentPositionID === data.commentPositionID) {
                            setComments([...item.commentsArray])
                        }
                    })

                    isAddCommentBtnClicked.current = false;
                }).catch((err) => {
                    console.log(err)
                })
            };
        };

        function deleteCommentFunction(id) {
            if (data) {
                deleteComment(moodBoard, id, data.commentPositionID, imageID).then((res) => {

                    setPreviewImageData(res.data);

                    let isCommentPointFound = false;

                    let newArray = [];

                    if (res.data.comments.length == 0) {
                        setComments([]);
                    } else {
                        res.data.comments.forEach((item) => {
                            if (item.commentPositionID === data.commentPositionID) {
                                isCommentPointFound = true;
                                newArray = item.commentsArray;
                            }
                        })
                    }

                    if (isCommentPointFound === true) {
                        setComments([...newArray]);
                    } else {
                        setComments([]);
                    }

                }).catch((err) => {
                    console.log(err)
                })
            }
        }
        
        const contextMenuWidthPos = useMemo(() => 100 * (data.xPos / imageGridSize.x), [data.xPos, imageGridSize.x]);
        const contextMenuHeightPos = useMemo(() => 100 * (data.yPos / imageGridSize.y), [data.yPos, imageGridSize.y]);

        useEffect(() => {
            
            setPosition({ x: contextMenuWidthPos, y: contextMenuHeightPos });
            
            setComments(data.commentsArray);
            
        }, [data])

        const [isActive, setIsActive] = useState(false);

        function SingleComment({ comment }) {

            const [menuActive, setMenuActive] = useState(false)

            const isClicked = useRef(false);

            const sendDeleteRequest = (e) => {
                if (isClicked.current === false) {
                    isClicked.current = true
                    deleteCommentFunction(comment.id)
                } 
            }

            const openMenu = (e) => {
                setMenuActive(true)
            }

            const closeMenu = (e) => {
                setMenuActive(false);
            }

            return (
                <li className='comment-body comment-user' onClick={openMenu} onMouseLeave={closeMenu} >
                    <p>{comment.comment}</p>
                    <p style={{ fontSize: '10px' }}>{comment.time}</p>
                    <div className={`comment-menu ${menuActive ? 'active' : ''}`} >
                        <p onClick={sendDeleteRequest}>Delete</p>
                    </div>
                </li>
            )
        }

        return (
            <>
                { comments.length !== 0 ? <div className='comment-container' style={{ top: `${position.y}%`, left: `${position.x}%` }}>
                        <div className={`comment-icon ${ isActive ? 'active' : '' }`} id={`comment-${data.xPos}-${data.yPos}`} onMouseEnter={(e) => setIsActive(true)} onMouseLeave={(e) => setIsActive(false)} >
                            <img src={CommentIcon} alt="Comment icon" />
                        </div>
                        <div ref={container} className={`comment-field ${isActive ? 'active' : ''}`} onMouseEnter={(e) => setIsActive(true)} onMouseLeave={(e) => setIsActive(false)} >
                            <ul className='comments-scroll-list' ref={commentsList} >
                                {comments.map((comment) => {
                                    if (comment.users_uid === inMemoryID.getID()) {
                                        return <SingleComment comment={comment} />
                                    } else {
                                        return (
                                            <li className='comment-body comment-non-user'>
                                                <p style={{fontSize: '10px'}}>{comment.users_email}</p>
                                                <p>{comment.comment}</p>
                                                <p style={{fontSize: '10px'}}>{comment.time}</p>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                            { isUserHaveAccess === true ? <div className='context-menu-input' ref={commentInput}>
                                <textarea
                                    ref={input}
                                    placeholder="Enter new comment here"
                                    onChange={updateStateValue}
                                    maxLength={200}
                                />
                                <div className="context-menu-button-cont">
                                    <button onClick={sendNewCommentRequest}>Add</button>
                                    <p style={{margin: '0 0 0 30px'}}>{ input.current ? commentTextLength : 0}/200</p>
                                </div>
                            </div> : null}
                        </div>
                    </div> : null}
            </>
        )
    }

    useEffect(() => {
        if (image != undefined) {
            setImageID(image.id)
            if (image.comments) {

                if (!image.imageGridSize) {
                    GetImageAspectRatio(image.url, (err, data) => {
                        setCommentsToTheState(data)
                    })
                } else {
                    setCommentsToTheState(image.imageGridSize)
                }

            } else {
                setCommentsArray([]);
                setCommentsComponents([]);
            }
        };
    }, [image]);

    return (
        <>
            {commentsComponents}
        </>
    )
}

export default CommentsGrid