import { signIn_popup_open, search_keyword, signUp_popup_open } from '../scripts/script';
import { filters_open, SearchFilter } from '../components/filter';
import { FetchPhotos } from '../components/photos';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import sliders from '../images/sliders.svg';
import searchIcon from '../images/search.svg';
import '../styles/style.css';
import { LoadingScreen } from '../components/loader';
import inMemoryID from '../services/id';
import SearchTextBar from '../components/textBar'
import { Header } from '../components/header';

function ReturnMain() {

    const navigate = useNavigate();

    const queryRef = useRef(null);

    const main = useRef(null);

    const queryHandler = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (queryRef.current.value == undefined) {
        } else if (queryRef.current.value.replace(/\s/g, '') == '') {
        } else {
            const storageQuery = window.localStorage;
            const photoOrientation = storageQuery.getItem('orient');
            const photoColorTheme = storageQuery.getItem('photoColour');
            let sources = JSON.parse(storageQuery.getItem('searchSources'));

            let searchString = `/find/?query=${queryRef.current.value}`;

            if (
                photoOrientation != 'null' &&
                photoOrientation &&
                photoOrientation != 'undefined'
            ) {
                searchString += `&photoOrientation=${photoOrientation}`;
            }
            if (
                photoColorTheme != 'null' &&
                photoColorTheme &&
                photoColorTheme != 'undefined'
            ) {
                searchString += `&photoColor=${photoColorTheme}`;
            }
            if (sources) {
                if (sources.flickr) {
                    searchString += `&flickrSearch=true`;
                }
                if (sources.unsplash) {
                    searchString += `&unsplashSearch=true`;
                }
                // if (sources.adobe) {
                //     searchString += `&adobeSearch=true`;
                // }
            }

            navigate(searchString);
        }
    };

    const getText = (e) => {
        search_keyword(e.target.innerText);
        queryHandler();
    };

    const MoveToBoards = () => {
        if (inMemoryID.getID()) {
            navigate("/boards/")
        } else {
            signUp_popup_open()
        }
    }

    return (
        <>
            <SearchFilter searchFunc={queryHandler} />
            <Header MoveToBoards={MoveToBoards} />
            <div id="title" style={{ display: 'block' }}>
                Create mood board in minutes!
            </div>
            <div id="sub-title" style={{ display: 'block' }}>
                Search, group and preview getty images in one place! Up to 60%
                more performing search!
            </div>
            <div style={{ padding: '0 17%' }}>
                <div className="block">
                    <div className="search-box">
                        <a
                            onClick={filters_open}
                            className="sliders"
                        >
                            <img src={sliders} className="img-sliders" />
                        </a>
                        <form onSubmit={queryHandler} style={{ width: '100%' }}>
                            <input
                                className="input-1"
                                id="input-1"
                                type="text"
                                placeholder="Search (enter keywords)"
                                autoComplete="on"
                                ref={queryRef}
                            />
                        </form>
                        <a onClick={queryHandler} id="search-button-2">
                            <img src={searchIcon} className="img-search"  />
                        </a>
                    </div>
                    
                </div>
                <div className="text-box">
                    <SearchTextBar searchKeyWords={[]} searchFunc={getText} />
                </div>
            </div>
            <div style={{overflow: 'hidden', height: '220vh'}}>
                <div className="main-screen-bottom">
                    <div className="main-screen" ref={main}>
                        <FetchPhotos />
                    </div>
                </div>
            </div>
            {/* <footer className='footer'>
      <section className='footer-section'></section>
      <section className='footer-section'></section>
      <section className='footer-section'></section>
  </footer> */}
        </>
    );
}

function Main() {
    // loader function
    const [loading, setLoading] = useState(true);
    const body = document.getElementsByTagName('body')[0];

    useEffect(() => {
        body.style.overflow = body.style.overflow === 'hidden' ? 'auto' : 'hidden';
        setTimeout(() => {
            setLoading(false);
            body.style.overflow = 'auto';
        }, 3000);
    }, []);

    return (
        <>
            {loading ? (
                <div className="loader">
                    <LoadingScreen loading={loading} />
                </div>
            ) : null}
            <ReturnMain />
        </>
    );
}

export default Main;
