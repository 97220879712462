import React, { useEffect, useRef, useState } from "react"
import '../styles/style.css'
import DB from '../db'
import trash from '../images/trash.svg'
import rename from '../images/rename-icon.svg'
import edit from '../images/edit.svg'
import duplicate from '../images/duplicate-icon.svg'
import share from '../images/share-2.svg'
import { useNavigate } from 'react-router-dom'
import { saveMBinDB, editMBinDB, deleteMBfromDB } from '../requests/boardsAPI'
import { ErrorAlert } from '../components/errors'
import { SharePopUp } from '../components/popups/share'
import { sharePopUp_open, boardsLimitReachedPopUpOpen } from '../scripts/script'
import DeleteMoodBoardPopUp from '../components/popups/deleteBoardPopUp'
import { deleteBoardPopUpOpen } from '../scripts/script'
import inMemoryID from '../services/id'
import { sendDownloadRequest } from '../requests/imagesAPI'
import CommentIcon from '../images/comment.svg';

function BoardsImagePreview(props) {

    const navigateTo = useNavigate()

    const [errorMessage, setErrorMessage] = useState()

    function returnBoardsImage(source, index, isCommented) {
        return (
            <div className='grid-item-boards'>
                {isCommented == true ? <div className='comment-icon'>
                    <img src={CommentIcon} alt="Comment icon" />
                </div> : null}
                <img src={source} key={`board-image-${index}`} />
            </div>
        )
    }

    const navigateToPreview = () => {
        navigateTo(`/boards/${props.item.mbid}/`)
    }

    const [MBname, setMBname] = useState()

    // useRef for input with MB name
    const name = useRef()

    const gridRef = useRef()

    const [packery, setPackery] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            if (gridRef.current) {
                setPackery(props.initPackeryFunc(props.index))
            }
        }, 100)
        setMBname(props.item.name)
    }, [props.index])

    // deleting MoodBoard function
    async function deleteMB() {
        
        try {
            await deleteMBfromDB(props.item.mbid).then((res) => {
                DB.moodBoards.delete(props.item.id)
                setTimeout(() => {
                    props.deleteFunc(props.index)
                }, 300)
                deleteBoardPopUpOpen(props.item.id)
            })
        } catch (err) {
            setErrorMessage(err.response.data)
        }
        
    }

    // open delete moodboard popup
    const openDeletePopup = () => {
        deleteBoardPopUpOpen(props.item.id)
    }

    const notFound = useRef(false);

    // creating dublicate of moodboard
    async function cloneMB() {

        notFound.current = true;
        
        const moodBoard = await DB.moodBoards.get(props.item.id)
        
        let i = 1;

        if (!props.isBoardCopying.current) {
            props.isBoardCopying.current = true;
            while (notFound.current) {

                i++;
                const MoodBoardWithSameName = await DB.moodBoards.where({ name: MBname + ` ${i}` }).toArray()
                
                if (!MoodBoardWithSameName[0]) {

                    const newArray = [];

                    moodBoard.photos.forEach((image) => {
                        image.comments = [];

                        newArray.push(image)
                    })

                    await saveMBinDB(newArray, MBname + ` ${i}`, []).then(async (res) => {
                        DB.moodBoards.add({
                            name: MBname + ` ${i}`,
                            photos: newArray,
                            mbid: res.data._id,
                            created_by: inMemoryID.getID(),
                            recentSearches: [],
                            users_with_access: []
                        })

                        const allImagesDownloadURLs = [];

                        newArray.forEach((e) => {
                            if (e.download_location) {
                                allImagesDownloadURLs.push(e.download_location);
                            }
                        })

                        if (allImagesDownloadURLs[0]) {
                            await sendDownloadRequest(allImagesDownloadURLs)
                        }

                        setTimeout(() => {
                            if (props.isBoardCopying) {
                                props.isBoardCopying.current = false;
                            }
                        }, 500)

                    }).catch((err) => {
                        if (err.response) {
                            if (err.response.data.error.includes('You have reached the maximum number') && err.response.data.status === 403) {
                                boardsLimitReachedPopUpOpen()
                            } else {
                                setErrorMessage(err.response.data)
                            }
    
                            props.isBoardCopying.current = false;
                        } else {
                            setErrorMessage({ error: 'The server is not responding', status: 500 })
                        }

                    })
                    notFound.current = false;
                }
            }
        }
        await props.getAllBoards()
    }

    //renaming functions
    function renameMB() {
        name.current.disabled = false
        name.current.select()
    }

    const onChangeMBname = (event) => {
        event.preventDefault()
        setMBname(event.target.value)
    }

    const saveName = async (e) => {
        e.preventDefault();
        if (MBname.trim() == '') {
            setMBname(props.item.name)
        } else {
            name.current.disabled = true;
            try {
                await editMBinDB(props.item.photos, MBname, props.item.mbid, props.item.recentSearches).then((res) => {
                    DB.moodBoards.update(props.item.id, { name: MBname })
                })
            } catch (err) {
                setErrorMessage(err.response.data)
            }
            
        }
    }

    const editMB = () => {

        const searchStocks = JSON.parse(window.localStorage.getItem('searchSources'));

        let searchParamsString = '';

        if (searchStocks) {

            if (searchStocks.flickr) { searchParamsString += '&flickrSearch=true' }
    
            if (searchStocks.unsplash) { searchParamsString += '&unsplashSearch=true' }
    
            // if (searchStocks.adobe) { searchParamsString += '&adobeSearch=true' }

        }

        
        navigateTo(`/find/${props.item.mbid}/?query=${searchParamsString}`)
    }

    const shareMB = () => {
        sharePopUp_open(props.item.id)
    }
    
    if (props.item.photos.length == 0) {
        return (
            <></>
        )
    } else {
        
        const imagesArray = [];

        const sortedEditArray = props.item.photos.sort(function(a, b) {
            return a.order - b.order;
        });

        let timeBeforeLayout = 0;

        sortedEditArray.forEach((i, count) => {
            count++
            timeBeforeLayout += 0.3
            imagesArray.push(returnBoardsImage(i.url, count, !i.comments[0] ? false : true))
        }) 

        if (packery) {
            setTimeout(() => {
                packery.layout()
            }, timeBeforeLayout * 1000)
        }

        return (
            <>
                <ErrorAlert message={errorMessage} styles={{position: 'absolute', margin: '5% auto'}} />
                <SharePopUp board={props.item} boardId={{current: props.item.mbid}} uniqueId={props.item.id} key={"share-board-popup-"+props.item.id} />
                <DeleteMoodBoardPopUp id={props.item.id} deleteFunc={deleteMB} key={"delete-board-popup-"+props.item.id} />
                <div className="rect-cont" ref={gridRef} key={props.index} id={props.item.id}>
                    <div className="rect" >
                        <div className="more-vertical">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className="dropdown">
                            <div style={{marginTop:"20px"}} onClick={cloneMB}>
                                <img src={duplicate} alt="duplicate icon" />
                                <p>Duplicate</p>
                            </div>
                            <div onClick={renameMB}>
                                <img src={rename} alt="rename icon" style={{ height: '16px', margin: '2px 15px 2px 3px'}} />
                                <p>Rename</p>
                            </div>
                            <div onClick={editMB}>
                                <img src={edit} alt="edit icon" />
                                <p>Edit</p>
                            </div>
                            <div onClick={shareMB}>
                                <img src={share} alt="share icon" /> 
                                <p>Share</p>
                            </div>
                            <div onClick={openDeletePopup}>
                                <img src={trash} alt="delete icon" /> 
                                <p>Delete</p>
                            </div>
                        </div>
                        <div onClick={navigateToPreview} className='grid-bottom'>
                            <div className="grid" id={'boards-grid-'+props.index}>
                                <div className='grid-width'></div>
                                <div className='boards-grid-gutter'></div>
                                {imagesArray}
                            </div>  
                        </div> 
                    </div> 
                    <form onSubmit={saveName}>
                        <input className='moodBoard-boards-name' disabled maxLength={50} ref={name} onChange={onChangeMBname} placeholder='Untitled board' value={MBname} />
                    </form>
                </div>     
            </>
        )
    }
}

export default BoardsImagePreview