import React, { useState, useEffect, useRef } from "react"
import { createNewComment } from '../requests/boardsAPI';
import { useParams } from 'react-router-dom'

function ContextMenu({ isVisible, setVisible, data, setPreviewImageData }) {

    let { commentPosition, yPos, xPos, imageData } = data;

    const menu = useRef();

    const textAreaRef = useRef();

    let { moodBoard } = useParams();

    const [position, setPosition] = useState({ x: null, y: null });

    const [inputValue, setInputValue] = useState('');

    function setFocus() {
        if (isVisible == true && textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }

    useEffect(() => {
        setFocus();
    }, [isVisible])

    function setMenuPosition(previewImageParam, contextMenuParam, pos) {

        const menuCorrelationToImage = 100 * (contextMenuParam / previewImageParam);

        const summary = menuCorrelationToImage + pos;

        setFocus()

        if (pos < 0) {
            pos = 0
        }

        if (summary > 100) {
            // if context menu falls out of the image by width
            const difference = summary - 100;

            return pos - difference
        } else {
            return pos
        }
    }

    function closeAddCommentInput(e) {
        setVisible(false)
    }

    function updateStateValue(text) {
        if (text.length < 201) {
            setInputValue(text)
        }
    }

    function sendNewCommentRequest(e) {
        if (inputValue != '' && inputValue) {

            createNewComment(inputValue, imageData.id, commentPosition.x, commentPosition.y, moodBoard).then(async (res) => {

                setInputValue('')

                setVisible(false)

                setPreviewImageData(res.data);
    
            }).catch((err) => {
                
            }).then(() => {
                setTimeout(() => {
                    try {
                        const createdComment = document.querySelector(`#comment-${commentPosition.x}-${commentPosition.y}`);
    
                        createdComment.classList.add('active');

                        createdComment.nextElementSibling.classList.add('active');
                    } catch (err) {

                    }
                }, 500)
            })
        }
    }

    useEffect(() => {
        const previewImage = document.querySelector('.preview-image');

        // const previewImageWidth = previewImage.offsetWidth;
        const previewImageHeight = previewImage.offsetHeight;

        // const contextMenuWidth = menu.current.offsetWidth;
        const contextMenuHeight = menu.current.offsetHeight;
        
        const contextMenuWidthPos = xPos;

        const contextMenuHeightPos = setMenuPosition(previewImageHeight, contextMenuHeight, yPos);

        setPosition({ x: contextMenuWidthPos, y: contextMenuHeightPos })

    }, [data])

    return (
        <div ref={menu} className={`context-menu ${isVisible ? 'active' : ''}`} style={{ top: `${position.y}%`, left: `${position.x}%` }}>
            <div className={`context-menu-input`}>
                <textarea
                    ref={textAreaRef}
                    placeholder="Enter new comment here"
                    value={inputValue}
                    onChange={(e) => { updateStateValue(e.target.value) }}
                    maxLength={200}
                />
                <div className="context-menu-button-cont">
                    <button onClick={sendNewCommentRequest}>Add</button>
                    <button onClick={closeAddCommentInput}>Cancel</button>
                    <p>{inputValue.length}/200</p>
                </div>
            </div>
        </div>
    )
}

export default ContextMenu