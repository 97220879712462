import React, { useEffect, useRef, useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import '../styles/style.css'

export function ErrorAlert({ message, styles }) {

    const [errorMessage, setErrorMessage] = useState({ status: null, error: null });
    
    const popup = useRef();

    useEffect(() => {
        if (message && !errorMessage.error) {
            popup.current.classList.toggle('active')
            
            setErrorMessage(message)
            setTimeout(() => {
                setErrorMessage({ status: null, error: null })
                popup.current.classList.toggle('active')
            }, 5000)
        }
    }, [message])

    return (
        <>
            <div className='error' ref={popup} style={styles ? styles : {}}>
                <Stack sx={{ width: '100%', transition: '300ms' }} spacing={2}>
                    <Alert variant="outlined" sx={{ bgcolor: 'background.paper', transition: '300ms'  }} severity="error">
                        <AlertTitle>ERR_STATUS_CODE: {errorMessage.status}</AlertTitle>
                        {errorMessage.error}
                    </Alert>
                </Stack>
            </div>
        </>
    )
}



export function ErrorPopUp({ message }) {

    const popup = useRef();

    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (message && !errorMessage) {
            popup.current.classList.toggle('active')
            setErrorMessage(message.toString())
            setTimeout(() => {
                setErrorMessage(null)
                popup.current.classList.toggle('active')
            }, 5000)
        }
    }, [message])

    return (
        <div className='error-popup' ref={popup} >
            <p>{errorMessage}</p>
        </div>
    );
}