import React, { useRef } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import grid from '../images/grid.svg'
import sideBar from '../images/sidebar.svg'
import '../styles/style.css'

function CreateSelectors() {
    const selectBlock = document.querySelector(".main-block");  
    const fieldBlock = document.querySelector("#enter-field");
    const ChangeView1 = () => {
        let sliders1 = document.querySelector(".show-style-sliders-1");
        sliders1.classList.add("sa");
        let sliders2 = document.querySelector(".show-style-sliders-2");
        sliders2.classList.remove("sa");
        fieldBlock.style.display = "flex";
        selectBlock.style.display = "none";
    }

    const ChangeView2 = () => {
        let sliders2 = document.querySelector(".show-style-sliders-2");
        sliders2.classList.add("sa");
        let sliders1 = document.querySelector(".show-style-sliders-1");
        sliders1.classList.remove("sa");
        fieldBlock.style.display = "none";
        selectBlock.style.display = "flex";
    }

    
    return (
        <div className="show-style" id="show-block">
            <div className="show-style-sliders-1" onClick={ChangeView1}>
                <img src={grid} className="img-sliders"/>
            </div>
            <div className="show-style-sliders-2 sa" style={{marginLeft: '11px'}} onClick={ChangeView2}>
                <img src={sideBar} className="img-sliders"/>
            </div>
        </div>  
    )
}

export { CreateSelectors }