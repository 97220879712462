import APIclient from './client'
import { backEndPayments } from '../constants/API'

const paymentClient = new APIclient(backEndPayments);

export async function makePayment() {
    const response = await paymentClient.post("/buy-subscription")

    window.location.assign(response.data.url)
}

export async function manageSubscriptionPage() {
    const response = await paymentClient.get("/billing-portal")

    window.location.assign(response.data.url)
}