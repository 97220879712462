const frontEndDomain = 'https://'+window.location.host

const backEndAuth = 'https://app-tp5u7an4bq-uc.a.run.app/auth'

const backEndMoodBoards = 'https://boardsapi-tp5u7an4bq-uc.a.run.app/boards'

const backEndImages = 'https://imagesapi-tp5u7an4bq-uc.a.run.app/images'

const backEndPayments = 'http://localhost:4000/payment'

export { frontEndDomain, backEndAuth, backEndMoodBoards, backEndImages, backEndPayments }