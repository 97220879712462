import React, { useState, useRef, useEffect } from "react";
import { forgotPass_popup_open } from "../../scripts/script";
import inMemoryID from '../../services/id'
import { resetPassword } from '../../requests/authAPI'
import '../../styles/style.css';

function ForgotPasswordPopUp(props) {

    const isChangePassRequestSend = useRef(false);

    const input = useRef();

    const [content, setContent] = useState();

    const redirectFunc = useRef();

    useEffect(() => {
        setContent(<button onClick={resetPass} className="btn-confirm" style={{marginBottom:"30px"}}>Restore password</button>)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (inMemoryID.getID()) {
                redirectFunc.current = function restorePassword() {
                    let forgot = document.querySelector("#forgot");
                    forgot.classList.toggle("active");
                    let deleteProfile = document.querySelector("#delete-profile")
                    deleteProfile.classList.toggle("active")
                }
            } else {
                redirectFunc.current = forgotPass_popup_open;
            }
        }, 1500)
    }, [])

    const resetPass = async (e) => {

        e.preventDefault();

        if (isChangePassRequestSend.current === false && input.current.value != '') {

            await resetPassword(input.current.value);

            setContent(<p style={{marginTop: '14px'}}>Check your email address</p>)
    
            isChangePassRequestSend.current = true;
        }
    }

    return (
        <div className="all" id="forgot">
            <a onClick={redirectFunc.current} className="popup-close"></a>
                <div className="forgot-pass-popup">
                    <div className="input-box">
                    <h2>Restore password</h2>
                    <p style={{color:"grey", margin: "59px 0 0 0"}}>Email</p>
                    <div className="search-box-3">
                        <input className="input-2" type="email" autoComplete="on" ref={input} required/>
                    </div>
                    {content}
                    </div>
                </div>
        </div>
    )
}

export {ForgotPasswordPopUp}