import { auth } from '../firebaseConnect';
import { makeRefresh } from '../requests/refreshToken'


const inMemoryJWTService = () => {
    let inMemoryJWT = null;
    let refreshTimeOutID = null

    const getToken = () => inMemoryJWT;

    const setToken = (token, tokenExpiration) => {
        inMemoryJWT = token
        refreshToken(tokenExpiration)
    }

    const abortRefreshToken = () => {
        if (refreshTimeOutID) {
            clearInterval(refreshTimeOutID)
        }
    }

    const deleteToken = () => {
        inMemoryJWT = null
        abortRefreshToken()
        localStorage.setItem("logout", Date.now())
    }

    const refreshToken = (expiration) => {
        const timeOutTrigger = expiration - 10000

        refreshTimeOutID = setInterval(async () => {
            await makeRefresh(auth.currentUser).catch((err) => {})
        }, timeOutTrigger)
    }

    return { getToken, setToken, deleteToken, refreshToken }
};

export default inMemoryJWTService()